import AWS from "aws-sdk";

// AWS Configuration
AWS.config.update({
  accessKeyId: "AKIAQZLQ3CH7DPRAR5MZ",
  secretAccessKey: "CkC2PeRZChZeCF0E7OxbHKmcL23PUQ4aR9nIhCAc",
  region: "eu-west-1", // e.g., 'eu-west-1'
});

export const s3 = new AWS.S3();
