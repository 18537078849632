import { useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { s3 } from "../../config/aws-config";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useOrganizationContainer from "../../containers/useOrganizationContainer";
import FormikInput from "../formik/FormikInput";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import {
  AWS_BUCKET_NAME,
  AWS_CATEGORIES_PATH,
  notAuthError,
  url,
} from "../../constants";

import Tooltip from "../Tooltip";
// import { ReactComponent as EditIcon } from "../../ui/icons/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../ui/icons/DeleteIcon.svg";

import { ReactComponent as ChamberVaultIcon } from "../../assets/images/categoryPresetsIcons/Single/Chamber_Vault.svg";
import { ReactComponent as FibreEnclosureIcon } from "../../assets/images/categoryPresetsIcons/Single/Fibre Enclosure.svg";
import { ReactComponent as PONSplitterIcon } from "../../assets/images/categoryPresetsIcons/Single/PON Splitter.svg";
import { ReactComponent as RackIcon } from "../../assets/images/categoryPresetsIcons/Single/Rack.svg";
import { ReactComponent as RadioAntennaIcon } from "../../assets/images/categoryPresetsIcons/Single/Radio Antenna.svg";
import { ReactComponent as SpliceTrayIcon } from "../../assets/images/categoryPresetsIcons/Single/Splice Tray.svg";
import { ReactComponent as SpliceIcon } from "../../assets/images/categoryPresetsIcons/Single/Splice.svg";
import { ReactComponent as StreetCabinetIcon } from "../../assets/images/categoryPresetsIcons/Single/Street Cabinet.svg";
import { ReactComponent as SwitchIcon } from "../../assets/images/categoryPresetsIcons/Single/Switch.svg";
import { ReactComponent as WIFIAccessPointIcon } from "../../assets/images/categoryPresetsIcons/Single/WiFi Access Point.svg";

import ChamberVault from "../../assets/images/categoryPresetsIcons/Single/Chamber_Vault.svg";
import FibreEnclosure from "../../assets/images/categoryPresetsIcons/Single/Fibre Enclosure.svg";
import PONSplitter from "../../assets/images/categoryPresetsIcons/Single/PON Splitter.svg";
import Rack from "../../assets/images/categoryPresetsIcons/Single/Rack.svg";
import RadioAntenna from "../../assets/images/categoryPresetsIcons/Single/Radio Antenna.svg";
import SpliceTray from "../../assets/images/categoryPresetsIcons/Single/Splice Tray.svg";
import Splice from "../../assets/images/categoryPresetsIcons/Single/Splice.svg";
import StreetCabinet from "../../assets/images/categoryPresetsIcons/Single/Street Cabinet.svg";
import Switch from "../../assets/images/categoryPresetsIcons/Single/Switch.svg";
import WIFIAccessPoint from "../../assets/images/categoryPresetsIcons/Single/WiFi Access Point.svg";

import { ReactComponent as FibreCableIcon } from "../../assets/images/categoryPresetsIcons/Dual/Fibre Cable.svg";
import { ReactComponent as PatchCableIcon } from "../../assets/images/categoryPresetsIcons/Dual/Patch Cable.svg";
import { ReactComponent as UndergroundDuctIcon } from "../../assets/images/categoryPresetsIcons/Dual/Underground Duct.svg";

import FibreCable from "../../assets/images/categoryPresetsIcons/Dual/Fibre Cable.svg";
import PatchCable from "../../assets/images/categoryPresetsIcons/Dual/Patch Cable.svg";
import UndergroundDuct from "../../assets/images/categoryPresetsIcons/Dual/Underground Duct.svg";

import styles from "../global idenetifiers/GlobalIdentifiers.module.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
};

const singlePresetIcons = [
  {
    name: "Chamber Vault",
    icon: <ChamberVaultIcon />,
    path: ChamberVault,
  },
  {
    name: "Fibre Enclosure",
    icon: <FibreEnclosureIcon />,
    path: FibreEnclosure,
  },
  { name: "PON Splitter", icon: <PONSplitterIcon />, path: PONSplitter },
  { name: "Rack", icon: <RackIcon />, path: Rack },
  { name: "Radio Antenna", icon: <RadioAntennaIcon />, path: RadioAntenna },
  { name: "Splice Tray", icon: <SpliceTrayIcon />, path: SpliceTray },
  { name: "Splice", icon: <SpliceIcon />, path: Splice },
  { name: "Street Cabinet", icon: <StreetCabinetIcon />, path: StreetCabinet },
  { name: "Switch", icon: <SwitchIcon />, path: Switch },
  {
    name: "WiFi Access Point",
    icon: <WIFIAccessPointIcon />,
    path: WIFIAccessPoint,
  },
];

const dualPresetIcons = [
  {
    name: "Fibre Cable",
    icon: <FibreCableIcon />,
    path: FibreCable,
  },
  {
    name: "Patch Cable",
    icon: <PatchCableIcon />,
    path: PatchCable,
  },
  {
    name: "Underground Duct",
    icon: <UndergroundDuctIcon />,
    path: UndergroundDuct,
  },
];

export default function Organization() {
  const { token } = useSelector((state) => state.login);
  const {
    organization,
    categories,
    updateCategories,
    formik,
    loading,
    submitLoading,
  } = useOrganizationContainer();
  const [DeleteIsOpen, setDeleteIsOpen] = useState(false);
  const [AddIsOpen, setAddIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [addedCategoryName, setAddedCategoryName] = useState("");
  const [addedCategoryImage, setAddedCategoryImage] = useState("");
  const [addedPresetCategoryImageName, setAddedPresetCategoryImageName] =
    useState("");
  const [addedCategoryType, setAddedCategoryType] = useState("single");
  const [addedFields, setAddedFields] = useState([]);
  const [addedDestinationFields, setAddedDestinationFields] = useState([]);
  const [fieldInputValue, setFieldInputValue] = useState("");
  const [destinationFieldInputValue, setDestinationFieldInputValue] =
    useState("");

  const [customCategoryImage, setCustomCategoryImage] = useState(false);

  // ! Firebase upload category logo [deprecated]
  // const storage = getStorage();
  // const handleUploadImage = async (eOrPath) => {
  //   let file;
  //   let metadata = {};

  //   // Check if eOrPath is an event (from an input file) or a string (SVG path)
  //   if (typeof eOrPath === "string") {
  //     // Fetch the SVG file from the provided path
  //     try {
  //       const response = await fetch(eOrPath);
  //       const text = await response.text();
  //       file = new File([text], `${eOrPath.split("/").pop()}`, {
  //         type: "image/svg+xml",
  //       });
  //     } catch (error) {
  //       console.error("Error fetching SVG from path", error);
  //       return;
  //     }
  //   } else if (eOrPath.target && eOrPath.target.files) {
  //     // Handle file upload from the input event
  //     file = eOrPath.target.files[0];
  //   } else {
  //     console.error("Unsupported input");
  //     return;
  //   }

  //   // Continue with existing logic for processing the file
  //   if (file.type === "image/svg+xml") {
  //     metadata = {
  //       contentType: "image/svg+xml",
  //     };

  //     try {
  //       // Read the SVG file
  //       const text = await file.text();
  //       const parser = new DOMParser();
  //       const svgDoc = parser.parseFromString(text, "image/svg+xml");

  //       // Function to apply color overlay
  //       const applySvgColorOverlay = (svgElement, color) => {
  //         // Create a rect element that covers the entire SVG
  //         const rect = document.createElementNS(
  //           "http://www.w3.org/2000/svg",
  //           "rect"
  //         );
  //         rect.setAttribute("width", "100%");
  //         rect.setAttribute("height", "100%");
  //         rect.setAttribute("fill", color);
  //         rect.setAttribute("fill-opacity", "0.5"); // Adjust opacity as needed

  //         // Append the rect to the SVG
  //         svgElement.appendChild(rect);
  //       };

  //       // Create color variations
  //       const colors = ["black", "rgb(93, 180, 63)", "rgb(202, 75, 69)"];

  //       for (let i = 0; i < colors.length; i++) {
  //         const color = colors[i];
  //         const coloredSvg = svgDoc.cloneNode(true);

  //         // Apply color overlay
  //         // applySvgColorOverlay(coloredSvg.documentElement, color);

  //         // Convert the modified SVG back to a string
  //         const serializer = new XMLSerializer();
  //         const coloredSvgString = serializer.serializeToString(coloredSvg);

  //         // Create a new file with the colored SVG content
  //         const coloredFile = new File(
  //           [coloredSvgString],
  //           `${file.name.replace(".svg", "")}_${color}.svg`,
  //           { type: "image/svg+xml" }
  //         );

  //         // Upload the colored SVG
  //         const coloredStorageRef = ref(
  //           storage,
  //           `categories/${organization.id}/${coloredFile.name}`
  //         );
  //         await uploadBytes(coloredStorageRef, coloredFile, metadata);
  //         const downloadURL = await getDownloadURL(coloredStorageRef);

  //         // You might want to store these URLs in an array or object
  //         console.log(`Download URL for ${color} SVG:`, downloadURL);
  //       }

  //       // Set the black version as the addedCategoryImage (you can modify this as needed)
  //       const blackSvgRef = ref(
  //         storage,
  //         `categories/${organization.id}/${file.name.replace(
  //           ".svg",
  //           ""
  //         )}_black.svg`
  //       );
  //       const blackDownloadURL = await getDownloadURL(blackSvgRef);
  //       setAddedCategoryImage(blackDownloadURL);
  //     } catch (error) {
  //       console.error("Error processing or uploading SVG", error);
  //     }
  //   } else {
  //     console.error("Unsupported file type");
  //     return;
  //   }
  // };

  const handleUploadImage = async (eOrPath) => {
    let file;

    // Check if eOrPath is an event (from an input file) or a string (SVG path)
    if (typeof eOrPath === "string") {
      // Fetch the SVG file from the provided path
      try {
        const response = await fetch(eOrPath);
        const text = await response.text();
        file = new File([text], `${eOrPath.split("/").pop()}`, {
          type: "image/svg+xml",
        });
      } catch (error) {
        console.error("Error fetching SVG from path", error);
        return;
      }
    } else if (eOrPath.target && eOrPath.target.files) {
      // Handle file upload from the input event
      file = eOrPath.target.files[0];
    } else {
      console.error("Unsupported input");
      return;
    }

    if (file.type === "image/svg+xml") {
      try {
        // Read the SVG file
        const text = await file.text();
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(text, "image/svg+xml");

        // Function to apply color overlay
        const applySvgColorOverlay = (svgElement, color) => {
          const rect = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          rect.setAttribute("width", "100%");
          rect.setAttribute("height", "100%");
          rect.setAttribute("fill", color);
          rect.setAttribute("fill-opacity", "0.5"); // Adjust opacity as needed
          svgElement.appendChild(rect);
        };

        // Create color variations
        const colors = ["black", "rgb(93, 180, 63)", "rgb(202, 75, 69)"];

        for (let i = 0; i < colors.length; i++) {
          const color = colors[i];
          const coloredSvg = svgDoc.cloneNode(true);

          // Apply color overlay
          // applySvgColorOverlay(coloredSvg.documentElement, color);

          // Convert the modified SVG back to a string
          const serializer = new XMLSerializer();
          const coloredSvgString = serializer.serializeToString(coloredSvg);

          // Create a new file with the colored SVG content
          const coloredFile = new File(
            [coloredSvgString],
            `${file.name.replace(".svg", "")}_${color}.svg`,
            { type: "image/svg+xml" }
          );

          // Prepare S3 upload parameters
          const params = {
            Bucket: AWS_BUCKET_NAME, // Replace with your S3 bucket name
            Key: `${AWS_CATEGORIES_PATH}${organization.id}/${coloredFile.name}`, // S3 object key
            Body: coloredFile,
            ContentType: "image/svg+xml",
          };

          try {
            // Upload to S3
            const data = await s3.upload(params).promise();
            console.log(`Uploaded ${color} SVG to S3:`, data.Location);

            // Optionally store URLs in an array or state
            if (color === "black") {
              setAddedCategoryImage(data.Location); // Assuming setAddedCategoryImage is defined
            }
          } catch (err) {
            console.error(`Error uploading ${color} SVG to S3`, err);
          }
        }
      } catch (error) {
        console.error("Error processing or uploading SVG", error);
      }
    } else {
      console.error("Unsupported file type");
      return;
    }
  };

  const handleDeleteCategory = (category) => {
    fetch(`${url}/api/organizations/categories/${category.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        web: true,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        }

        if (resData.error && resData.error[0]) {
          toast(resData.error[0].message);
        }
        if (resData.message) {
          toast.success(resData.message);
          updateCategories();
        }
      });
  };
  const handleCreateCategory = () => {
    let errors = 0;
    if (addedCategoryName === "") {
      toast.error("category name is required");
      errors++;
    }
    if (addedFields.length === 0) {
      toast.error("category must have fields");
      errors++;
    }
    if (addedCategoryType === "dual" && addedDestinationFields.length === 0) {
      toast.error("category of type dual must have destination fields");
      errors++;
    }
    if (errors > 0) return;
    const newCategory = {
      name: addedCategoryName,
      image: addedCategoryImage,
      LOC_type: addedCategoryType,
      fields: addedFields,
      organization: organization.id,
    };
    if (addedCategoryType === "dual") {
      newCategory.destination_fields = addedDestinationFields;
    }
    fetch(`${url}/api/organizations/categories`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        web: true,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newCategory),
    })
      .then((res) => res.json())
      .then((resData) => {
        toast.success(resData.message);
        setAddIsOpen(false);
        // Reset form fields
        setAddedCategoryName("");
        setAddedCategoryImage("");
        setAddedCategoryType("single");
        setAddedFields([]);
        updateCategories();
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  return (
    <div className="container">
      <ToastContainer />
      <div className="row">
        <div className="col-md-8 offset-md-2 offset-0 col-12">
          <h5 className="text-center my-3">{`Edit Organization (${
            !loading && organization.name
          })`}</h5>

          <form onSubmit={formik.handleSubmit}>
            <FormikInput formik={formik} name="name" />
            <div className="d-flex justify-content-center my-3">
              <button
                type="submit"
                className="btn btn-primary w-25 mx-2 hamada"
                style={{ height: "40px" }}
              >
                {submitLoading ? (
                  <PropagateLoader color={"#fff"} css={override} size={10} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>

          <hr></hr>
          <div>
            <div className="text-end">
              <h2 className="text-center mb-4">LOC Categories</h2>
              <button
                className="btn btn-primary rounded-2 mb-2"
                onClick={(e) => {
                  setAddIsOpen(true);
                }}
              >
                Add Category
              </button>
            </div>
            <h3>Single LOC Categories</h3>
            <table className="table table-bordered">
              <thead className="sticky top-0 z-50 border border-secondary ">
                <tr className="bg-primary text-white rounded-t-lg">
                  <th scope="col" className="text-center">
                    Name
                  </th>
                  <th scope="col" className="text-center">
                    Image
                  </th>
                  <th scope="col" className="text-center">
                    Fields
                  </th>
                  <th scope="col" className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>

              {categories &&
              categories.filter((category) => category.LOC_type === "single")
                .length > 0 ? (
                <tbody>
                  <tr className="text-center">
                    <td>default</td>
                    <td className="w-fit">
                      <div className="flex justify-center">
                        <img
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"
                          }
                          alt={"default"}
                          className="h-8"
                        />
                      </div>
                    </td>

                    <td style={{ maxWidth: "300px" }}>
                      <div className="flex flex-wrap justify-center">
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 1
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 2
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 3
                        </span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  {categories
                    .filter((category) => category.LOC_type === "single")
                    .map((category) => {
                      return (
                        <tr className="text-center" key={category.id}>
                          <td>{category.name}</td>
                          <td className="w-fit">
                            <div className="flex justify-center">
                              <img
                                src={
                                  category.image ||
                                  "https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"
                                }
                                alt={category.name}
                                className="h-8"
                              />
                            </div>
                          </td>

                          <td style={{ maxWidth: "300px" }}>
                            <div className="flex flex-wrap justify-center">
                              {category.fields.map((field) => (
                                <span
                                  key={field}
                                  className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill"
                                >
                                  {field}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td>
                            {/* <Tooltip text="Edit category">
                              <button
                                className="btn z-10 p-0 m-0"
                                type="button"
                                onClick={() => {
                                }}
                              >
                                <EditIcon className="cursor-pointer" />
                              </button>
                            </Tooltip> */}
                            <Tooltip text="Delete category">
                              <button
                                className="btn p-0 m-0"
                                type="button"
                                onClick={() => {
                                  setDeleteIsOpen(true);
                                  setSelectedCategory(category);
                                }}
                              >
                                <DeleteIcon className="cursor-pointer" />
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody>
                  <tr className="text-center">
                    <td>default</td>
                    <td className="w-fit">
                      <div className="flex justify-center">
                        <img
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"
                          }
                          alt={"default"}
                          className="h-8"
                        />
                      </div>
                    </td>

                    <td style={{ maxWidth: "300px" }}>
                      <div className="flex flex-wrap justify-center">
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 1
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 2
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 3
                        </span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              )}
            </table>

            <h3>Dual LOC Categories</h3>
            <table className="table table-bordered">
              <thead className="sticky top-0 z-50  border border-secondary">
                <tr className="bg-primary text-white rounded-t-lg">
                  <th scope="col" className="text-center">
                    Name
                  </th>
                  <th scope="col" className="text-center">
                    Image
                  </th>
                  <th scope="col" className="text-center">
                    Origin Fields
                  </th>
                  <th scope="col" className="text-center">
                    Destination Fields
                  </th>
                  <th scope="col" className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>

              {categories &&
              categories.filter((category) => category.LOC_type === "dual")
                .length > 0 ? (
                <tbody>
                  <tr className="text-center">
                    <td>default</td>
                    <td className="w-fit">
                      <div className="flex justify-center">
                        <img
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"
                          }
                          alt={"default"}
                          className="h-8"
                        />
                      </div>
                    </td>

                    <td style={{ maxWidth: "300px" }}>
                      <div className="flex flex-wrap justify-center">
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 1
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 2
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 3
                        </span>
                      </div>
                    </td>

                    <td style={{ maxWidth: "300px" }}>
                      <div className="flex flex-wrap justify-center">
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 1
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 2
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 3
                        </span>
                      </div>
                    </td>

                    <td></td>
                  </tr>
                  {categories
                    .filter((category) => category.LOC_type === "dual")
                    .map((category) => {
                      return (
                        <tr className="text-center" key={category.id}>
                          <td>{category.name}</td>
                          <td className="w-fit">
                            <div className="flex justify-center">
                              <img
                                src={
                                  category.image ||
                                  "https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"
                                }
                                alt={category.name}
                                className="h-8"
                              />
                            </div>
                          </td>

                          <td style={{ maxWidth: "300px" }}>
                            <div className="flex flex-wrap justify-center">
                              {category.fields.map((field) => (
                                <span
                                  key={field}
                                  className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill"
                                >
                                  {field}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td style={{ maxWidth: "300px" }}>
                            <div className="flex flex-wrap justify-center">
                              {category.destination_fields?.map((field) => (
                                <span
                                  key={field}
                                  className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill"
                                >
                                  {field}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td>
                            {/* <Tooltip text="Edit category">
                              <button
                                className="btn z-10 p-0 m-0"
                                type="button"
                                onClick={() => {
                                }}
                              >
                                <EditIcon className="cursor-pointer" />
                              </button>
                            </Tooltip> */}
                            <Tooltip text="Delete category">
                              <button
                                className="btn p-0 m-0"
                                type="button"
                                onClick={() => {
                                  setDeleteIsOpen(true);
                                  setSelectedCategory(category);
                                }}
                              >
                                <DeleteIcon className="cursor-pointer" />
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody>
                  <tr className="text-center">
                    <td>default</td>
                    <td className="w-fit">
                      <div className="flex justify-center">
                        <img
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/loc-flutter-app.appspot.com/o/categories%2Fsettings-svgrepo-com.svg?alt=media&token=5055c3d7-1499-4fd4-bb01-5c3ef56b72fd"
                          }
                          alt={"default"}
                          className="h-8"
                        />
                      </div>
                    </td>

                    <td style={{ maxWidth: "300px" }}>
                      <div className="flex flex-wrap justify-center">
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 1
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 2
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 3
                        </span>
                      </div>
                    </td>

                    <td style={{ maxWidth: "300px" }}>
                      <div className="flex flex-wrap justify-center">
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 1
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 2
                        </span>
                        <span className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill">
                          field 3
                        </span>
                      </div>
                    </td>

                    <td></td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>

      <Modal
        isOpen={AddIsOpen}
        style={customStyles}
        onRequestClose={() => {
          setAddedCategoryName("");
          setAddedCategoryType("single");
          setCustomCategoryImage(false);
          setAddedFields([]);
          setAddedDestinationFields([]);
          setAddIsOpen(false);
        }}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">Add Category</h5>

              <form onSubmit={() => handleCreateCategory()}>
                <div>
                  <input
                    type="text"
                    className="form-control p-2"
                    id="categoryName"
                    name="categoryName"
                    placeholder="Category Name"
                    style={{ borderColor: "#ccc" }}
                    value={addedCategoryName}
                    onChange={(e) => setAddedCategoryName(e.target.value)}
                  />

                  <select
                    id="select"
                    className="form-select mt-2 p-2"
                    value={addedCategoryType}
                    onChange={(e) => setAddedCategoryType(e.target.value)}
                  >
                    <option value="single">single</option>
                    <option value="dual">dual</option>
                  </select>

                  <div className="mt-1 d-flex justify-content-center">
                    {customCategoryImage ? (
                      <div className="flex my-2 border border-grey rounded-lg ps-2 align-items-center">
                        <label htmlFor="image" className="col-2 text-secondary">
                          Logo
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image"
                          name="image"
                          onChange={(e) => handleUploadImage(e)}
                          placeholder="category image"
                          accept=".svg,image/svg+xml"
                        />
                      </div>
                    ) : (
                      <div className="dropdown w-full my-2">
                        <button
                          className="btn btn-light w-full dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {addedPresetCategoryImageName === ""
                            ? "Choose a Logo"
                            : addedPresetCategoryImageName}
                        </button>
                        <ul className="dropdown-menu">
                          {addedCategoryType === "single"
                            ? singlePresetIcons.map((icon, index) => (
                                <li key={index}>
                                  <button
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setAddedPresetCategoryImageName(
                                        icon.name
                                      );
                                      handleUploadImage(icon.path);
                                    }}
                                  >
                                    {icon.icon} {icon.name}
                                  </button>
                                </li>
                              ))
                            : dualPresetIcons.map((icon, index) => (
                                <li key={index}>
                                  <button
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setAddedPresetCategoryImageName(
                                        icon.name
                                      );
                                      handleUploadImage(icon.path);
                                    }}
                                  >
                                    {icon.icon} {icon.name}
                                  </button>
                                </li>
                              ))}
                        </ul>
                      </div>
                    )}

                    <div
                      className="btn-group my-2 ms-2"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autocomplete="off"
                        onChange={() => setCustomCategoryImage(false)}
                        checked={!customCategoryImage}
                      />
                      <label
                        className="btn btn-outline-primary"
                        for="btnradio1"
                      >
                        Preset
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autocomplete="off"
                        checked={customCategoryImage}
                        onChange={() => setCustomCategoryImage(true)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        for="btnradio2"
                      >
                        Custom
                      </label>
                    </div>
                  </div>
                </div>
              </form>
              <div className="bg-light rounded-lg p-3 my-4">
                <h5 className="">
                  {addedCategoryType === "single" ? "" : "Origin "}Fields
                </h5>

                <div
                  className="border border-secondary rounded-lg p-1"
                  style={{
                    maxHeight: "200px",
                    minHeight: "100px",
                    overflowY: "scroll",
                  }}
                >
                  <ReactSortable list={addedFields} setList={setAddedFields}>
                    {addedFields.map(
                      (field, index) =>
                        (
                          <span
                            key={index}
                            className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill"
                            style={{ cursor: "grab" }}
                          >
                            {field} | &nbsp;
                            <span
                              className="font-bold hover"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setAddedFields(
                                  addedFields.filter((_, i) => i !== index)
                                );
                              }}
                            >
                              x
                            </span>
                          </span>
                        ) || "No fields"
                    )}
                  </ReactSortable>
                </div>

                <div className="flex mt-2">
                  <input
                    type="text"
                    className="form-control p-2"
                    placeholder="Field Name"
                    style={{ borderColor: "#ccc" }}
                    onChange={(e) => setFieldInputValue(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && e.target.value !== "") {
                        setAddedFields([...addedFields, fieldInputValue]);
                        setFieldInputValue("");
                      } else {
                        e.preventDefault();
                      }
                    }}
                    value={fieldInputValue}
                  />
                  <button
                    className="btn btn-primary ms-1 col-2"
                    onClick={() => {
                      if (fieldInputValue === "") return;
                      setAddedFields([...addedFields, fieldInputValue]);
                      setFieldInputValue("");
                    }}
                  >
                    + field
                  </button>
                </div>
              </div>

              {addedCategoryType === "dual" && (
                <div className="bg-light rounded-lg p-3 my-4">
                  <h5 className="">Destination Fields</h5>

                  <div
                    className="border border-secondary rounded-lg p-1"
                    style={{
                      maxHeight: "200px",
                      minHeight: "100px",
                      overflowY: "scroll",
                    }}
                  >
                    <ReactSortable
                      list={addedDestinationFields}
                      setList={setAddedDestinationFields}
                    >
                      {addedDestinationFields.map(
                        (field, index) =>
                          (
                            <span
                              key={index}
                              className="d-inline-block text-sm px-2 py-1 m-1 text-white font-semi-bold bg-secondary rounded rounded-pill"
                              style={{ cursor: "grab" }}
                            >
                              {field} | &nbsp;
                              <span
                                className="font-bold hover"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setAddedDestinationFields(
                                    addedDestinationFields.filter(
                                      (_, i) => i !== index
                                    )
                                  );
                                }}
                              >
                                x
                              </span>
                            </span>
                          ) || "No fields"
                      )}
                    </ReactSortable>
                  </div>

                  <div className="flex mt-2">
                    <input
                      type="text"
                      className="form-control p-2"
                      placeholder="Field Name"
                      style={{ borderColor: "#ccc" }}
                      onChange={(e) => {
                        setDestinationFieldInputValue(e.target.value);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter" && e.target.value !== "") {
                          setAddedDestinationFields([
                            ...addedDestinationFields,
                            destinationFieldInputValue,
                          ]);
                          setDestinationFieldInputValue("");
                        } else {
                          e.preventDefault();
                        }
                      }}
                      value={destinationFieldInputValue}
                    />
                    <button
                      className="btn btn-primary ms-1 col-2"
                      onClick={() => {
                        if (destinationFieldInputValue === "") return;
                        setAddedDestinationFields([
                          ...addedDestinationFields,
                          destinationFieldInputValue,
                        ]);
                        setDestinationFieldInputValue("");
                      }}
                    >
                      + field
                    </button>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-success mx-2"
                  onClick={() => {
                    handleCreateCategory();
                  }}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => {
                    setAddIsOpen(false);
                    setAddedCategoryName("");
                    setAddedCategoryType("single");
                    setCustomCategoryImage(false);
                    setAddedFields([]);
                    setAddedDestinationFields([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={DeleteIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete this category ?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={() => {
                    setDeleteIsOpen(false);
                    handleDeleteCategory(selectedCategory);
                    setSelectedCategory(null);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
