import { Link } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { s3 } from "../../config/aws-config";
import { AWS_BUCKET_NAME, AWS_IMAGES_PATH } from "../../constants";
import { app } from "../../config/firebase-config";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import Modal from "react-modal";
import styles from "./../project/Project.module.css";

import { ReactComponent as EditIcon } from "../../ui/icons/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../ui/icons/DeleteIcon.svg";
import { ReactComponent as RecycleBinIcon } from "../../assets/images/recycle-bin-empty_svgrepo.com.svg";

import { ReactComponent as PlayIcon } from "../../assets/images/play.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // paddingBottom: "0%",
    borderRadius: "10px",
  },
};

function DualLocsTable({
  dualLocs,
  selectedDualLocs,
  onSelectLoc,
  onSelectAllDualLocs,
  sortType,
  locSorted,
  renderedItem,
  notUser,
  setDualLocID,
  setDeleteDualIsOpen,
  setRestoreDualIsOpen,
  isBin,
  notAdminOrUser,
  handleOpenLockModal,
  loadSearch,
  role,
  sortTable,
  searchDualLocs,
  searchValue,
  globalSearch,
}) {
  const loggedInUserId = localStorage.getItem("userId");
  const loggedInThirdPartyId = localStorage.getItem("thirdParty_id");

  const [imageGalleryIsOpen, setImageGalleryIsOpen] = useState(false);
  const [imageLoc, setImageLoc] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [audioGalleryIsOpen, setAudioGalleryIsOpen] = useState(false);
  const [audioLoc, setAudioLoc] = useState(null);

  function downloadFile(attachments) {
    if (!Array.isArray(attachments)) {
      attachments = [attachments];
    }

    if (attachments[0].includes("amazonaws.com")) {
      const bucketName = AWS_BUCKET_NAME;
      const params = {
        Bucket: bucketName,
        Key:
          AWS_IMAGES_PATH +
          attachments[0].substring(attachments[0].lastIndexOf("/") + 1), // File name in the S3 bucket
      };

      const url = s3.getSignedUrl("getObject", params);
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => processBlob(blob))
        .catch((error) => toast.error("Error downloading attachment"));
    } else {
      const storage = getStorage(app);
      Promise.all(
        attachments.map((att) => getDownloadURL(ref(storage, att)))
      ).then((urls) => {
        urls.forEach((url) => {
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => processBlob(blob))
            .catch((error) => toast.error("Error downloading attachment"));
        });
      });
    }
  }

  async function processBlob(blob) {
    let processedBlob = blob;
    let fileExtension = blob.type.split("/")[1];

    // Check if the blob is an octet-stream
    if (blob.type === "application/octet-stream") {
      try {
        processedBlob = await convertToJpg(blob);
        fileExtension = "jpg";
      } catch (error) {
        console.error("Error converting image:", error);
        toast.error("Error converting image");
        return;
      }
    }

    const url = window.URL.createObjectURL(processedBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${processedBlob.type.split("/")[0]}-${Math.random()
      .toString(36)
      .substring(7)}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  function convertToJpg(blob) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(resolve, "image/jpeg");
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(blob);
    });
  }

  async function processBlob(blob) {
    let processedBlob = blob;
    let fileExtension = blob.type.split("/")[1];

    // Check if the blob is an octet-stream
    if (blob.type === "application/octet-stream") {
      try {
        processedBlob = await convertToJpg(blob);
        fileExtension = "jpg";
      } catch (error) {
        console.error("Error converting image:", error);
        toast.error("Error converting image");
        return;
      }
    }

    const url = window.URL.createObjectURL(processedBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${processedBlob.type.split("/")[0]}-${Math.random()
      .toString(36)
      .substring(7)}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  function convertToJpg(blob) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(resolve, "image/jpeg");
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(blob);
    });
  }

  return (
    <>
      <table
        className="table"
        style={{ fontSize: "12px", verticalAlign: "middle" }}
      >
        <thead className="sticky z-10 top-0">
          <tr className="bg-primary text-white text-uppercase">
            {role !== "user" && (
              <th
                className="whitespace-nowrap sticky left-0 ms-0 bg-primary"
                scope="col"
              >
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={
                    selectedDualLocs.toString() ===
                    dualLocs.map((loc) => loc.loc_id).toString()
                  }
                  onChange={() =>
                    onSelectAllDualLocs(dualLocs.map((loc) => loc.loc_id))
                  }
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                />
              </th>
            )}
            {globalSearch && (
              <>
                <th className="whitespace-nowrap" scope="col">
                  GID
                </th>
                <th className="whitespace-nowrap" scope="col">
                  PROJECT
                </th>
                <th className="whitespace-nowrap" scope="col">
                  LOCATION
                </th>
              </>
            )}
            <th
              className="whitespace-nowrap"
              onClick={() => sortTable("dual", "unique_asset_id")}
            >
              Route ID
              <i
                className={`text-white far fa-arrow-${
                  sortType === "unique_asset_id" && locSorted === "dual"
                    ? "up"
                    : "down"
                } text-black mx-2`}
              ></i>
            </th>
            <th
              className="whitespace-nowrap"
              scope="col"
              onClick={() => sortTable("dual", "actual_asset_name")}
            >
              Origin
              <i
                className={`text-white far fa-arrow-${
                  sortType === "actual_asset_name" && locSorted === "dual"
                    ? "up"
                    : "down"
                } text-black mx-2`}
              ></i>
            </th>
            <th className="whitespace-nowrap" scope="col">
              FIELD 1
            </th>
            <th className="whitespace-nowrap" scope="col">
              FIELD 2
            </th>
            <th className="whitespace-nowrap" scope="col">
              FIELD 3
            </th>
            <th className="whitespace-nowrap" scope="col" colSpan="2">
              LOCATION
            </th>
            {/* <th scope="col">Soft Locked</th> */}
            {/* <th className="whitespace-nowrap" scope="col">
              Hard Locked
            </th> */}
            {/* <th scope="col">LATITUDE</th>
                        <th scope="col">LONGITUDE</th>
                        <th scope="col">RADIUS</th> */}
            <th className="whitespace-nowrap" scope="col">
              CABLE DESTINATION
            </th>
            <th className="whitespace-nowrap" scope="col">
              FIELD 1
            </th>
            <th className="whitespace-nowrap" scope="col">
              FIELD 2
            </th>
            <th className="whitespace-nowrap" scope="col">
              FIELD 3
            </th>
            <th
              className="whitespace-nowrap"
              scope="col"
              onClick={() => sortTable("dual", "destination")}
              colSpan="2"
            >
              DESTINATION
              <i
                className={`text-white far fa-arrow-${
                  sortType === "destination" && locSorted === "dual"
                    ? "up"
                    : "down"
                } text-black mx-2`}
              ></i>
            </th>
            {/* <th scope="col">Soft Locked</th> */}
            {/* <th className="whitespace-nowrap" scope="col">
              Hard Locked
            </th> */}
            {/* <th scope="col">LATITUDE</th>
                        <th scope="col">LONGITUDE</th>
                        <th scope="col">RADIUS</th> */}
            {/* <th scope="col">FIELD 1</th>
                        <th scope="col">FIELD 2</th> */}
            {/* <th scope="col">LOCATION</th> */}
            <th
              className="whitespace-nowrap"
              scope="col"
              onClick={() => sortTable("dual", "updatedAt")}
            >
              LAST UPDATE
              <i
                className={`text-white far fa-arrow-${
                  sortType === "updatedAt" && locSorted === "dual"
                    ? "up"
                    : "down"
                } text-black mx-2`}
              ></i>
            </th>
            <th
              className="whitespace-nowrap"
              scope="col"
              onClick={() => sortTable("dual", "email")}
            >
              LAST UPDATED BY
              <i
                className={`text-white far fa-arrow-${
                  sortType === "email" && locSorted === "dual" ? "up" : "down"
                } text-black mx-2`}
              ></i>
            </th>
            <th className="whitespace-nowrap" scope="col">
              NOTES
            </th>
            <th className="whitespace-nowrap" scope="col">
              IMAGES
            </th>
            <th className="whitespace-nowrap" scope="col">
              AUDIO
            </th>
            {role !== "user" && (
              <th
                className="whitespace-nowrap"
                scope="col"
                style={{ minWidth: "100px" }}
              >
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {renderedItem === "locs" ? (
            dualLocs.map((loc) => {
              return (
                <tr key={loc.loc_id}>
                  {role !== "user" && (
                    <td scope="row" className="sticky left-0 bg-white">
                      {(searchDualLocs.length === 0 &&
                        searchValue.trim() !== "") ||
                      dualLocs.length === 0 ? (
                        "Select"
                      ) : (
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          checked={selectedDualLocs.includes(loc.loc_id)}
                          onChange={() => onSelectLoc(loc.loc_id, "dual")}
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                        />
                      )}
                    </td>
                  )}
                  {globalSearch && (
                    <>
                      <td className="font-bold">
                        <Link
                          className="text-primary"
                          to={`/globalidenetifiers/projects/${loc?.Location?.Project?.GlobalIdentifier?.gid}`}
                        >
                          {loc?.Location?.Project?.GlobalIdentifier?.name}
                        </Link>
                      </td>
                      <td className="font-bold">
                        <Link
                          className="text-primary"
                          to={`/locations/${loc?.Location?.Project?.id}`}
                        >
                          {loc?.Location?.Project?.name}
                        </Link>
                      </td>
                      <td className="font-bold">
                        <Link
                          className="text-primary"
                          to={`/viewlocs/${loc?.Location?.id}`}
                        >
                          {loc?.Location?.name}
                        </Link>
                      </td>
                    </>
                  )}
                  <td>{loc.unique_asset_id}</td>
                  <td>{loc.actual_asset_name}</td>
                  <td>{loc.field_1}</td>
                  <td>{loc.field_2}</td>
                  <td>{loc.field_3}</td>
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Latitude : {loc.origin_latitude}{" "}
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Longitude : {loc.origin_longitude}
                    </span>
                  </td>
                  {/* <td>{loc.soft_lock? 'Yes' :'No'}</td> */}
                  {/* <td>{loc.hard_lock ? "Yes" : "No"}</td> */}

                  <td>
                    {isBin
                      ? loc.Bin_LOCDestination?.destination
                      : loc.LOCDestination?.destination}
                  </td>
                  <td>
                    {isBin
                      ? loc.Bin_LOCDestination?.destination_field_1
                      : loc.LOCDestination?.destination_field_1}
                  </td>
                  <td>
                    {isBin
                      ? loc.Bin_LOCDestination?.destination_field_2
                      : loc.LOCDestination?.destination_field_2}
                  </td>
                  <td>
                    {isBin
                      ? loc.Bin_LOCDestination?.destination_field_3
                      : loc.LOCDestination?.destination_field_3}
                  </td>
                  {/* <td>DF11</td>
                            <td>DF11</td> */}
                  {/* <td className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm w-75"
                                >
                                  view
                                </button>
                              </td> */}
                  {/* <td>{loc.LOCDestination?.latitude}</td>
                              <td>{loc.LOCDestination?.longitude}</td>
                              <td>{loc.LOCDestination?.radius}</td> */}
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Latitude :{" "}
                      {isBin
                        ? loc.Bin_LOCDestination?.latitude * 100
                        : loc.LOCDestination?.latitude * 100}{" "}
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Longitude :{" "}
                      {isBin
                        ? loc.Bin_LOCDestination?.longitude * 100
                        : loc.LOCDestination?.longitude * 100}{" "}
                    </span>
                  </td>

                  {/* <td>{loc.LOCDestination?.soft_lock? 'Yes' :'No'}</td> */}
                  {/* <td>{loc.LOCDestination?.hard_lock ? "Yes" : "No"}</td> */}

                  <td>{new Date(loc.updatedAt).toUTCString()}</td>
                  <td>{loc.User.email}</td>
                  <td>{loc.notes}</td>
                  <td>
                    {loc.imageNotes?.length > 0 ? (
                      <div
                        className="relative mx-auto"
                        style={{ width: "50px" }}
                        onClick={() => {
                          setImageGalleryIsOpen(true);
                          setImageLoc(loc);
                        }}
                      >
                        <img
                          className="img-fluid rounded-1"
                          style={{ height: "50px", width: "100px" }}
                          src={loc.imageNotes[0]}
                          alt="loc view"
                        />
                        <div className="absolute top-0 left-0 h-full w-full | bg-black/25 rounded-1 | flex justify-center align-items-center | text-white fs-5 fw-bold">
                          <p className="h-fit m-0">{loc.imageNotes.length}</p>
                        </div>
                      </div>
                    ) : (
                      <>No Images</>
                    )}
                  </td>
                  <td>
                    {loc.audioNotes?.length > 0 ? (
                      <div
                        className="flex align-center"
                        onClick={() => {
                          setAudioGalleryIsOpen(true);
                          setAudioLoc(loc);
                        }}
                      >
                        <PlayIcon />
                        <div className="p-1">
                          <p className="font-bold text-success m-0">
                            {loc.audioNotes.length}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <>No Audio</>
                    )}
                  </td>
                  {role !== "user" &&
                    (!loggedInThirdPartyId ||
                      (loggedInThirdPartyId &&
                        loc.user_id === loggedInUserId)) && (
                      <td style={{ paddingBottom: 10, paddingRight: 10 }}>
                        {isBin ? (
                          <>
                            {notUser.includes(role) && (
                              <button
                                className="btn btn-primary p-1 m-o"
                                type="button"
                                // onClick={(e) =>
                                //   dispatch(
                                //     onDeletingLoc(e, loc.loc_id, token, "dual")
                                //   )
                                // }
                                onClick={(e) => {
                                  setDualLocID(loc.loc_id);
                                  setRestoreDualIsOpen(true);
                                }}
                              >
                                <RecycleBinIcon className="cursor-pointer" />
                                {/* <i className="far fa-trash-alt text-danger"></i> */}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {!loc.soft_lock && (
                              <Link
                                to={"/UpdateDualLocInfo/" + loc.loc_id}
                                className="btn p-0 m-o mx-2"
                                type="button"
                              >
                                <EditIcon className="cursor-pointer" />
                                {/* <i className="fas fa-pencil-alt text-secondary"></i> */}
                              </Link>
                            )}
                            {notUser.includes(role) && (
                              <button
                                className="btn p-0 m-o"
                                type="button"
                                // onClick={(e) =>
                                //   dispatch(
                                //     onDeletingLoc(e, loc.loc_id, token, "dual")
                                //   )
                                // }
                                onClick={(e) => {
                                  setDualLocID(loc.loc_id);
                                  setDeleteDualIsOpen(true);
                                }}
                              >
                                <DeleteIcon className="cursor-pointer" />
                                {/* <i className="far fa-trash-alt text-danger"></i> */}
                              </button>
                            )}
                            {/* {notAdminOrUser.includes(role) && (
                          <button
                            className="btn p-0 m-o"
                            type="button"
                            onClick={() => {
                              handleOpenLockModal(loc);
                            }}
                          >
                            <i className="fas fa-lock soft"></i>
                          </button>
                        )} */}
                          </>
                        )}
                      </td>
                    )}
                </tr>
              );
            })
          ) : loadSearch ? (
            <div style={{ textAlign: "center", padding: "20px 0" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : searchDualLocs && searchDualLocs.length > 0 ? (
            searchDualLocs.map((loc) => {
              return (
                <tr key={loc.loc_id}>
                  <td scope="row" className="sticky left-0 bg-white">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      checked={selectedDualLocs.includes(loc.loc_id)}
                      onChange={() => onSelectLoc(loc.loc_id, "dual")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                    />
                  </td>
                  <td>{loc.unique_asset_id}</td>
                  <td>{loc.actual_asset_name}</td>
                  <td>{loc.field_1}</td>
                  <td>{loc.field_2}</td>
                  <td>{loc.field_3}</td>
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Latitude : {loc.origin_latitude}
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Longitude : {loc.origin_longitude}
                    </span>
                  </td>
                  {/* <td>{loc.soft_lock? 'Yes' :'No'}</td> */}
                  {/* <td>{loc.hard_lock ? "Yes" : "No"}</td> */}

                  <td>{loc.LOCDestination?.destination}</td>
                  <td>{loc.LOCDestination?.destination_field_1}</td>
                  <td>{loc.LOCDestination?.destination_field_2}</td>
                  <td>{loc.LOCDestination?.destination_field_3}</td>
                  {/* <td>DF11</td>
                      <td>DF11</td> */}
                  {/* <td className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm w-75"
                                >
                                  view
                                </button>
                              </td> */}
                  {/* <td>{loc.LOCDestination?.latitude}</td>
                              <td>{loc.LOCDestination?.longitude}</td>
                              <td>{loc.LOCDestination?.radius}</td> */}
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Latitude :{" "}
                      {isBin
                        ? loc.Bin_LOCDestination?.latitude
                        : loc.LOCDestination?.latitude * 100}{" "}
                    </span>
                  </td>
                  <td>
                    <span style={{ fontSize: "9px" }}>
                      Longitude :{" "}
                      {isBin
                        ? loc.Bin_LOCDestination?.longitude * 100
                        : loc.LOCDestination?.longitude * 100}{" "}
                    </span>
                  </td>

                  {/* <td>{loc.LOCDestination?.soft_lock? 'Yes' :'No'}</td> */}
                  {/* <td>{loc.LOCDestination?.hard_lock ? "Yes" : "No"}</td> */}

                  <td>{new Date(loc.updatedAt).toUTCString()}</td>
                  <td>{loc.User.email}</td>
                  <td>{loc.notes}</td>
                  <td>
                    {loc.imageNotes?.length > 0 ? (
                      <div
                        className="relative mx-auto"
                        style={{ width: "50px" }}
                        onClick={() => {
                          setImageGalleryIsOpen(true);
                          setImageLoc(loc);
                        }}
                      >
                        <img
                          className="img-fluid rounded-1"
                          style={{ height: "50px", width: "100px" }}
                          src={loc.imageNotes[0]}
                          alt="loc view"
                        />
                        <div className="absolute top-0 left-0 h-full w-full | bg-black/25 rounded-1 | flex justify-center align-items-center | text-white fs-5 fw-bold">
                          <p className="h-fit m-0">{loc.imageNotes.length}</p>
                        </div>
                      </div>
                    ) : (
                      <>No Images</>
                    )}
                  </td>
                  <td>
                    {loc.audioNotes?.length > 0 ? (
                      <div
                        className="flex align-center"
                        onClick={() => {
                          setAudioGalleryIsOpen(true);
                          setAudioLoc(loc);
                        }}
                      >
                        <PlayIcon />
                        <div className="p-1">
                          <p className="font-bold text-success m-0">
                            {loc.audioNotes.length}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <>No Audio</>
                    )}
                  </td>
                  {role !== "user" &&
                    (!loggedInThirdPartyId ||
                      (loggedInThirdPartyId &&
                        loc.user_id === loggedInUserId)) && (
                      <td style={{ paddingBottom: 10, paddingRight: 10 }}>
                        {isBin ? (
                          <>
                            {notUser.includes(role) && (
                              <button
                                className="btn btn-primary p-1 m-o"
                                type="button"
                                // onClick={(e) =>
                                //   dispatch(
                                //     onDeletingLoc(e, loc.loc_id, token, "dual")
                                //   )
                                // }
                                onClick={(e) => {
                                  setDualLocID(loc.loc_id);
                                  setRestoreDualIsOpen(true);
                                }}
                              >
                                <RecycleBinIcon className="cursor-pointer" />
                                {/* <i className="far fa-trash-alt text-danger"></i> */}
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {!loc.soft_lock && (
                              <Link
                                to={"/UpdateDualLocInfo/" + loc.loc_id}
                                className="btn p-0 m-o mx-2"
                                type="button"
                              >
                                <EditIcon className="cursor-pointer" />
                                {/* <i className="fas fa-pencil-alt text-secondary"></i> */}
                              </Link>
                            )}
                            {notUser.includes(role) && (
                              <button
                                className="btn p-0 m-o"
                                type="button"
                                // onClick={(e) =>
                                //   dispatch(
                                //     onDeletingLoc(e, loc.loc_id, token, "dual")
                                //   )
                                // }
                                onClick={(e) => {
                                  setDualLocID(loc.loc_id);
                                  setDeleteDualIsOpen(true);
                                }}
                              >
                                <DeleteIcon className="cursor-pointer" />
                                {/* <i className="far fa-trash-alt text-danger"></i> */}
                              </button>
                            )}
                            {/* {notAdminOrUser.includes(role) && (
                          <button
                            className="btn p-0 m-o"
                            type="button"
                            onClick={() => {
                              handleOpenLockModal(loc);
                            }}
                          >
                            <i className="fas fa-lock soft"></i>
                          </button>
                        )} */}
                          </>
                        )}
                      </td>
                    )}
                </tr>
              );
            })
          ) : (
            <tr className="">
              <td colSpan={15} className="text-center">
                loc doesn't exist
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal
        isOpen={imageGalleryIsOpen}
        style={customStyles}
        onRequestClose={() => {
          setImageGalleryIsOpen(false);
          setImageIndex(0);
        }}
        contentLabel="Delete Modal"
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div id="locGallery" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {imageLoc?.imageNotes.map((image, index) => (
              <div
                className={`carousel-item ${
                  index === imageIndex ? "active" : ""
                }`}
                data-bs-interval="99999999999"
              >
                <img
                  src={image}
                  className="img-fluid"
                  style={{ maxHeight: "90vh" }}
                  alt="loc"
                />
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#locGallery"
            data-bs-slide="prev"
            onClick={() =>
              setImageIndex(
                imageIndex <= 0
                  ? imageLoc?.imageNotes.length - 1
                  : imageIndex - 1
              )
            }
          >
            <span
              className="carousel-control-prev-icon bg-primary rounded-3"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#locGallery"
            data-bs-slide="next"
            onClick={() =>
              setImageIndex(
                imageIndex === imageLoc?.imageNotes.length - 1
                  ? 0
                  : imageIndex + 1
              )
            }
          >
            <span
              className="carousel-control-next-icon bg-primary rounded-3"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
          <div className="text-center py-2">
            <button
              className="btn btn-primary"
              onClick={() => downloadFile(imageLoc.imageNotes[imageIndex])}
            >
              Download
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={audioGalleryIsOpen}
        style={customStyles2}
        onRequestClose={() => {
          setAudioGalleryIsOpen(false);
        }}
      >
        <div className="flex flex-col gap-2">
          {audioLoc?.audioNotes.map((audio, index) => (
            <audio key={index} src={audio} controls />
          ))}
        </div>
      </Modal>
    </>
  );
}

export default DualLocsTable;
